import React from "react";
import OurServiceItem from "../../components/OurServiceItem/OurServiceItem";
import ExpertiseSection from "../../components/ExpertiseSection/ExpertiseSection";
import { Container } from "react-bootstrap";

import SOCJPG from "../../assets/images/service-images/SOC.jpg";
import VCCSOJPG from "../../assets/images/service-images/Virtual-Chief-Cyber-Security-Office.jpg";
import RTJPG from "../../assets/images/service-images/Red-Teaming.jpg";
import SAJPG from "../../assets/images/service-images/Security-Assessment.jpg";
import APTJPG from "../../assets/images/service-images/Application-Penetration-Testing.jpg";
import NPTPG from "../../assets/images/service-images/Network-Penetration-Testing.jpg";

import "./Services.css";

function Services() {
 
  const ourServicesRow1 = {
    imgSrc1: SOCJPG,
    title1: "Security Operations Centre – SOC",
    desc1:
      "This includes the collection and analysis of data to identify suspicious activity and improve the organization's security.",
    link1: "/services/security-operations-centre",
    imgSrc2: VCCSOJPG,
    title2: "Virtual Chief Cyber Security Officer",
    desc2:
      "Potential perpetration of cyber threats to your website is assessed through our website penetration testing.",
    link2: "/services/virtual-chief-cyber-security-officer",
  };
  const ourServicesRow2 = {
    imgSrc1: RTJPG,
    title1: "Red Teaming",
    desc1:
      "Potential perpetration of cyber threats to your website is assessed through our website penetration testing.",
    link1: "/services/red-teaming",
    imgSrc2: SAJPG,
    title2: "Web Application Security Assessment",
    desc2:
      "Potential perpetration of cyber threats to your website is assessed through our website penetration testing.",
    link2: "/services/web-application-security-assessment",
  };
  const ourServicesRow3 = {
    imgSrc1: APTJPG,
    title1: "Mobile Application Penetration Testing",
    desc1:
      "We perform a comprehensive audit on Android and IOS mobile application to fix OWASP vulnerabilities.",
    link1: "/services/mobile-application-penetration-testing",
    imgSrc2: NPTPG,
    title2: "Network Penetration Testing",
    desc2:
      "We protect the security of your network with our network penetration testing. We generate a complete cyber audit report on",
    link2: "/services/network-penetration-testing",
  };

  return (
    <>
      <Container className="container">
        <OurServiceItem
          title1={ourServicesRow1.title1}
          imgSrc1={ourServicesRow1.imgSrc1}
          desc1={ourServicesRow1.desc1}
          link1={ourServicesRow1.link1}
          title2={ourServicesRow1.title2}
          imgSrc2={ourServicesRow1.imgSrc2}
          desc2={ourServicesRow1.desc2}
          link2={ourServicesRow1.link2}
        />
        <OurServiceItem
          title1={ourServicesRow2.title1}
          imgSrc1={ourServicesRow2.imgSrc1}
          desc1={ourServicesRow2.desc1}
          link1={ourServicesRow2.link1}
          title2={ourServicesRow2.title2}
          imgSrc2={ourServicesRow2.imgSrc2}
          desc2={ourServicesRow2.desc2}
          link2={ourServicesRow2.link2}
        />
        <OurServiceItem
          title1={ourServicesRow3.title1}
          imgSrc1={ourServicesRow3.imgSrc1}
          desc1={ourServicesRow3.desc1}
          link1={ourServicesRow3.link1}
          title2={ourServicesRow3.title2}
          imgSrc2={ourServicesRow3.imgSrc2}
          desc2={ourServicesRow3.desc2}
          link2={ourServicesRow3.link2}
        />
        <ExpertiseSection />
      </Container>
    </>
  );
}

export default Services;
