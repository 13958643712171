import React from "react";
import MissionImageJPG from "../../assets/images/MissionImg.jpg";
import "./SingleCard.css";
import { Reveal } from "react-awesome-reveal";

function SingleCard() {
  const missions = [
    {
      id: 1,
      title: "Our Mission",
      desc: "To help you avail the absolute advantage of information technology in business sustenance and expansion, without the anxiety about the safety of your digital assets, through our affordable and innovate security solutions.",
    },
  ];

  const missionList = missions.map((mission, idx) => {
    return (
      <div className="choose-wrap" key={`missions_${idx}`}>
        <h2>{mission.title}</h2>
        <p className="p">{mission.desc}</p>
      </div>
    );
  });

  return (
    <div
      className="choose-area"
      style={{ backgroundImage: `url(${MissionImageJPG})` }}
    >
      {/* <img src={MissionImageJPG} alt="MissionImage" /> */}
      <Reveal triggerOnce duration={1000}>
      {missionList}
      </Reveal>
    </div>
  );
}

export default SingleCard;
