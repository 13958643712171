import React, { useEffect, useRef } from "react";
import "./PageTitle.css";
import PageTitleBlinker from "../../assets/images/background/page-bg.png";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";

function PageTitle({ location }) {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.style.height = location.pathname === "/" ? "0em" : "7em";
  }, [location]);

  return (
    <div className="page-title-container" ref={ref}>
      <div className="page-title-div">
        <img className="page-title-image" src={PageTitleBlinker} alt="banner" />
      </div>
      <div className="page-title-content">
        {location.pathname === "/" ? (
          <></>
        ) : (
          <ul>
            <li className=" font-coda">
              <Link   to={"/"}>
                Home
              </Link>
            </li>
            <li className="active font-coda">
           
                {location.pathname.replace("/", "")}
             
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default PageTitle;
