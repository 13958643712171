import React from "react";



const BatchFileInstructions = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h3>Two files </h3>
      <a
        href="/initialCheck.txt"
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        File to download-initialCheck.txt
      </a>
      <br />
      <a href="/crowdstrike.txt" download target="_blank" rel="noopener noreferrer">
        File to download-crowdstrike.txt
      </a>
      <h2>Steps to Run Batch Files</h2>
      <p>Follow these steps to ensure the batch files run correctly:</p>
      <ol>
        <li>
          <p>1.Place the files in the required directory and rename the extension of the file with (.bat)</p>
        </li>
        <li>
          <p>
            2.Modify the initialCheck.bat file. Specify the right path of the
            crowdstrike.bat file at the line starting with <code>schtasks</code>{" "}
            in place of "C:\path-to-perform-file-operations.bat". Save the file.
          </p>
        </li>
        <li>
          <p>
            3.Double click the initialCheck.bat file it opens in the default
            command line tool. It should execute without any error.
          </p>
        </li>
        <li>
          <p>
            4.Once the system boots up, the crowdstrike.bat file should execute
            automatically without error code.
          </p>
        </li>
      </ol>

      <h2>IMPORTANT</h2>
      <p>
        Please look at the details before execution to get an idea of what the
        script does:
      </p>

      <h3>initialCheck.bat</h3>
      <ol>
        <li>1.Checks for admin access</li>
        <li>2.Sets the boot mode to safe</li>
        <li>
          3.Creates a scheduled task - Provide the path of the crowdstrike.bat
          file
        </li>
        <li>4.System reboots</li>
      </ol>

      <h3>crowdstrike.bat</h3>
      <ol>
        <li>1.Checks admin access</li>
        <li>2.Checks if the system is in safeboot mode</li>
        <li>3.Sets the environment variable for the crowdstrike path</li>
        <li>4.Checks if it exists</li>
        <li>
          5.Sets the file pattern to check for and the extension of the file to
          be renamed as environment variables
        </li>
        <li>6.Renames the file</li>
        <li>
          7.Removes the scheduled task set before in initialCheck.bat file
        </li>
        <li>8.Deletes the safe boot in the configuration data</li>
        <li>9.Reboots</li>
      </ol>
    </div >
  );
};

export default BatchFileInstructions;
