import React from "react";
import { Col } from "react-bootstrap";
import "./ServiceListContact.css";

function ServiceListContact(props) {
  const joinedwords = props.joinedwords;
  return (
    <Col lg={4} md={12} className="services-2col">
      <div className="services-details-order">
        <ul className="services-list">
          <li
            className={
              joinedwords === "Security Operations Centre"
                ? "active-services"
                : ""
            }
          >
            <a href="/services/security-operations-centre">
              Security Operations Centre
              <br />
              <br />
            </a>
          </li>
          <li
            className={
              joinedwords === "Virtual Chief Cyber Security Officer"
                ? "active-services"
                : ""
            }
          >
            <a href="/services/virtual-chief-cyber-security-officer">
              Virtual Chief Cyber Security Officer <br />
              <br />
            </a>
          </li>
          <li
            className={joinedwords === "Red Teaming" ? "active-services" : ""}
          >
            <a href="/services/red-teaming">
              Red Teaming <br />
              <br />
            </a>
          </li>
          <li
            className={
              joinedwords === "Web Application Security Assessment"
                ? "active-services"
                : ""
            }
          >
            <a href="/services/web-application-security-assessment">
              Web Application Security Assessment <br />
              <br />
            </a>
          </li>
          <li
            className={
              joinedwords === "Mobile Application Penetration Testing"
                ? "active-services"
                : ""
            }
          >
            <a href="/services/mobile-application-penetration-testing">
              Mobile Application Penetration Testing <br />
              <br />
            </a>
          </li>
          <li
            className={
              joinedwords === "Network Penetration Testing"
                ? "active-services"
                : ""
            }
          >
            <a href="/services/network-penetration-testing">
              Network Penetration Testing <br />
              <br />
            </a>
          </li>
        </ul>
        <div className="services-contact-info">
          <h3>Contact Info</h3>
          <ul>
            <li>
              <div className="icon">
                <i className="fa-regular fa-address-card"></i>
              </div>
              <span>Phone:</span>
              <a href="tel:+91-861-014-0034" target="_blank">
                +91-861-014-0034
              </a>
            </li>
            <li>
              <div className="icon">
                <i className="fa-solid fa-location-dot"></i>
              </div>
              <span>Location :</span>
              Chennai, Tamil Nadu
            </li>
            <li>
              <div className="icon">
                <i className="fa-regular fa-envelope"></i>
              </div>
              <span>Email :</span>
              <a href="maito:support@necurity.com">support@necurity.com</a>
            </li>
          </ul>
        </div>
      </div>
    </Col>
  );
}

export default ServiceListContact;
