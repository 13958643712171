import React from "react";
import { useState } from "react";
import "./PopoutOnClick.css";

function PopoutOnClick(props) {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  return (
    <div>
      <div className="tab-title" onClick={handleClick}>
        <i className="fa-accordion-icon fas fa-plus icon"></i>
        <span className="method-list-title">{props.title}</span>
        <i className="fa-toggle fas fa-angle-right"></i>
      </div>
      {isShown && (
        <div className="onclick-tab">
          <p>{props.desc}</p>
        </div>
      )}
    </div>
  );
}

export default PopoutOnClick;
