import React from "react";
import { useLocation } from "react-router-dom";
import SOCJPG from "../../../assets/images/service-images/SOC.jpg";
import { Col, Row, Image, Container } from "react-bootstrap";
import ServiceListContact from "../../../components/ServiceListContact/ServiceListContact";
import SAJPG from "../../../assets/images/service-images/Security-Assessment.jpg";
import APTJPG from "../../../assets/images/service-images/Application-Penetration-Testing.jpg";
import VCCSOJPG from "../../../assets/images/service-images/Virtual-Chief-Cyber-Security-Office.jpg";

function SOC() {

  const location = useLocation();
  console.log(location);
  const { pathname } = location;
  const splitpath = pathname.split("/");
  const splitwords = splitpath[2].split("-");
  for (let i = 0; i < splitwords.length; i++) {
    splitwords[i] = splitwords[i][0].toUpperCase() + splitwords[i].substr(1);
  }

  const joinedwords = splitwords.join(" ");
  console.log(joinedwords);
  return (
    <div>
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <div className="services-details">
              <Image src={SOCJPG} />
              <div className="choose-wrap-services serviceposter">
                <h2>Security Operations Centre- SOC</h2>
              </div>
              <h3>What is a SOC?</h3>
              <p className="para-align-services">
                A security operations centre, or SOC, is a team of IT security
                professionals protects the organization by monitoring,
                detecting, analysing, and investigating cyber threats. Networks,
                servers, computers, endpoint devices, operating systems,
                applications and databases are continuously examined for signs
                of a cyber security incident. The SOC team analyses feeds,
                establishes rules, identifies exceptions, enhances responses and
                keeps a look out for new vulnerabilities.
              </p>
              <h3>How does a SOC work?</h3>
              <p className="para-align-services">
                The primary mission of the SOC is security monitoring and
                alerting. This includes the collection and analysis of data to
                identify suspicious activity and improve the organization’s
                security. Threat data is collected from firewalls, security
                information and event management (SIEM) systems. Alerts are sent
                out to SOC team members as soon as discrepancies, abnormal
                trends or other indicators of compromise are picked up.
              </p>
              <h3>What is SIEM?</h3>
              <p className="para-align-services">
                Security information and event management (SIEM)is a security
                technology that aggregates log datafrom multiple sources,
                identifies suspicious activityand takes appropriate action.
              </p>
              <div className="choose-wrap-services serviceposter">
                <h2>
                  A SIEM solution helps you collect all these alerts in one
                  place. It creates a single set of reports in acentralized
                  system for generating notifications. This is also referred to
                  as a log aggregation system.
                </h2>
              </div>
              <h3>FEATURES OF SIEM</h3>
              <h3>LOG DATA AGGREGATION</h3>
              <p className="para-align-services">
                Collect and aggregate data from multiple data sources, like
                network devices, security devices andcloud services.
              </p>
              <h3>THREAT INTELLIGENCE</h3>
              <p className="para-align-services">
                Custom data enrichment with external threat intelligence
                providers
              </p>
              <h3>CORRELATION AND ANALYTICS</h3>
              <p className="para-align-services">
                Connect the dots between related security events to see the
                complete picture.
              </p>
              <h3>THREAT HUNTING</h3>
              <p className="para-align-services">
                Derive insights from your log and event data by writing queries.
              </p>
              <h3>SECURITY COMPLIANCE</h3>
              <p className="para-align-services">
                Keep your organization audit-ready with comprehensive reporting.
              </p>
              <h3>REAL-TIME SECURITY MONITORING</h3>
              <p className="para-align-services">
                Monitor key metrics and traffic profiles to identify anomalies.
              </p>
              <h3>ALERTING</h3>
              <p className="para-align-services">
                Detect issues and notify incident handlers for further
                investigation or remediation.
              </p>
              <h3>DASHBOARDS</h3>
              <p className="para-align-services">
                Give your team a unified security overview, making it easier to
                identify anomalies with threats.
              </p>
              <h3>INCIDENT RESPONSE</h3>
              <p className="para-align-services">
                Identify and investigate incidents faster by bringing in
                relevant context and threat information.
              </p>
              <h3>SECURITY AUTOMATION</h3>
              <p className="para-align-services">
                Detect, validate and respond to threats without lifting a
                finger, thanks to built-in security <br />
                orchestration, automation and response (SOAR) functionality.
              </p>
            </div>
          </Col>
          <ServiceListContact joinedwords={joinedwords} />
        </Row>
        <h3 className="related-posts">Related Posts</h3>
        <Row>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${SAJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Web Application Security Assessment
                  <br />
                  <br />
                </h3>
                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>
                <a
                  className="read-more"
                  href="/services/web-application-security-assessment"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${VCCSOJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Virtual Chief Cyber Security Officer
                  <br />
                  <br />
                </h3>
                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>
                <a
                  className="read-more"
                  href="/services/virtual-chief-cyber-security-officer"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${APTJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Mobile Application Penetration Testing
                  <br />
                  <br />
                </h3>
                <p>
                  We perform a comprehensive audit on Android and IOS mobile
                  application to fix OWASP vulnerabilities.
                </p>
                <a
                  className="read-more"
                  href="/services/mobile-application-penetration-testing"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SOC;
