import React, { useState } from "react";
import "./ContactForm.css";
import Form from "react-bootstrap/Form";
import { Button, Col, Row } from "react-bootstrap";
import useForm from "../../hooks/useForm";
// import axios from "axios";
import { createRecord } from "./airtableService";

const initialFormState = {
  name: "",
  email: "",
  contact_no: "",
  subject: "",
  message: "",
};

const validationRules = {
  name: {
    required: true,
  },
  email: {
    required: true,
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
  contact_no: {
    required: true,
    minLength: 10,
    maxLength: 12,
  },
  subject: {
    required: true,
    minLength: 10,
  },
  message: {
    required: true,
    minLength: 10,
    maxLength: 1024,
  },
};

function ContactForm() {
  const {
    formState,
    errors,
    handleChange,
    handleChangeTextarea,
    handleChangeSelect,
    handleSubmit,
    resetForm,
  } = useForm(initialFormState, validationRules, onSubmit);

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handleCheckboxChange = () => {
    setPrivacyChecked(!privacyChecked);
  };

  async function onSubmit(data) {
    try {
      const createdRecord = await createRecord(data);
      console.log("Record created:", createdRecord);
      // reset();
      alert("Form submitted successfully");

      resetForm();
      setPrivacyChecked(false);
    } catch (error) {
      console.error("Error creating record:", error);
      alert("Failed to submit the form. Please try again.");
    }
  }
  return (
    <Form className="p-5" onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Your Name</Form.Label>
            <Form.Control
              className="contact-input rounded-0 shadow-none"
              type="name"
              placeholder="Your Name"
              name="name"
              value={formState.name}
              onChange={handleChange}
            />
            <Form.Text className="text-danger">
              {errors.name &&
                errors.name.map((error, idx) => {
                  return (
                    <React.Fragment key={`error-name-${idx}`}>
                      {error} <br />
                    </React.Fragment>
                  );
                })}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Your Email</Form.Label>
            <Form.Control
              className="contact-input rounded-0 shadow-none"
              type="email"
              placeholder="Your Email"
              name="email"
              value={formState.email}
              onChange={handleChange}
            />
            <Form.Text className="text-danger">
              {errors.email &&
                errors.email.map((error, idx) => {
                  return (
                    <React.Fragment key={`error-email-${idx}`}>
                      {error} <br />
                    </React.Fragment>
                  );
                })}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="contact_no">
            <Form.Label>Your Number</Form.Label>
            <Form.Control
              className="contact-input rounded-0 shadow-none"
              type="tel"
              placeholder="Your Number"
              name="contact_no"
              value={formState.contact_no}
              onChange={handleChange}
            />
            <Form.Text className="text-danger">
              {errors.contact_no &&
                errors.contact_no.map((error, idx) => {
                  return (
                    <React.Fragment key={`error-contact_no-${idx}`}>
                      {error} <br />
                    </React.Fragment>
                  );
                })}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="subject">
            <Form.Label>Your Subject</Form.Label>
            <Form.Control
              className="contact-input rounded-0 shadow-none"
              type="text"
              placeholder="Your Subject"
              name="subject"
              value={formState.subject}
              onChange={handleChange}
            />
            <Form.Text className="text-danger">
              {errors.subject &&
                errors.subject.map((error, idx) => {
                  return (
                    <React.Fragment key={`error-subject-${idx}`}>
                      {error} <br />
                    </React.Fragment>
                  );
                })}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 " controlId="messasge">
            <Form.Label>Your Message</Form.Label>
            <Form.Control
              className="contact-input rounded-0 shadow-none"
              as="textarea"
              placeholder="Your Message"
              rows={9}
              name="message"
              value={formState.message}
              onChange={handleChange}
            />
            <Form.Text className="text-danger">
              {errors.message &&
                errors.message.map((error, idx) => {
                  return (
                    <React.Fragment key={`error-message-${idx}`}>
                      {error} <br />
                    </React.Fragment>
                  );
                })}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label="Privacy policy."
          required
          checked={privacyChecked}
          onChange={handleCheckboxChange}
        />
      </Form.Group>

      <Form.Group className="d-flex justify-content-end">
        <Button
          className="contact-btn shadow-none"
          variant="primary"
          type="submit"
        >
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
}

export default ContactForm;