import { useState } from "react";

function useForm(initialFormState, validationRules, onSubmit) {
  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState({});

  function validateField(name, value) {
    const rules = validationRules[name];
    const replaced_name = name.replace("_", " ");
    const capitalized_name =
      replaced_name.charAt(0).toUpperCase() + replaced_name.slice(1);

    const errorMessages = [];

    if (rules.pattern && !rules.pattern.test(value)) {
      errorMessages.push(`Invalid ${capitalized_name}`);
    }

    if (rules.required && !value) {
      errorMessages.push(`${capitalized_name} is required`);
    }

    if (rules.validate && !rules.validate(value)) {
      errorMessages.push(`Invalid ${capitalized_name}`);
    }

    if (rules.minLength && value.length < rules.minLength) {
      errorMessages.push(
        `${capitalized_name} must be at least ${rules.minLength} characters`
      );
    }

    if (rules.maxLength && value.length > rules.maxLength) {
      errorMessages.push(
        `${capitalized_name} must be less than ${rules.maxLength} characters`
      );
    }

    return errorMessages;
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const fieldErrors = validateField(name, value);
    setErrors((prevState) => ({
      ...prevState,
      [name]: fieldErrors,
    }));
  }

  const handleChangeTextarea = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));

    const fieldErrors = validateField(name, value);
    setErrors((prevState) => ({
      ...prevState,
      [name]: fieldErrors,
    }));
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));

    const fieldErrors = validateField(name, value);
    setErrors((prevState) => ({
      ...prevState,
      [name]: fieldErrors,
    }));
  };

  function handleSubmit(event) {
    event.preventDefault();

    const newErrors = {};

    for (const [name, value] of Object.entries(formState)) {
      const fieldErrors = validateField(name, value);

      if (fieldErrors.length) {
        newErrors[name] = fieldErrors;
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      onSubmit(formState);
    }
  }

  const resetForm = () => {
    setFormState(initialFormState);
    setErrors({});
  };

  return {
    formState,
    setFormState,
    errors,
    handleChange,
    handleChangeTextarea,
    handleChangeSelect,
    handleSubmit,
    resetForm
  };
}

export default useForm;
