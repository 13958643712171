import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ServiceListContact from "../../../components/ServiceListContact/ServiceListContact";
import NPTPG from "../../../assets/images/service-images/Network-Penetration-Testing.jpg";
import SOCJPG from "../../../assets/images/service-images/SOC.jpg";
import VCCSOJPG from "../../../assets/images/service-images/Virtual-Chief-Cyber-Security-Office.jpg";
import APTJPG from "../../../assets/images/service-images/Application-Penetration-Testing.jpg";

function NetworkPen() {
 
  const location = useLocation();
  console.log(location);
  const { pathname } = location;
  const splitpath = pathname.split("/");
  const splitwords = splitpath[2].split("-");
  for (let i = 0; i < splitwords.length; i++) {
    splitwords[i] = splitwords[i][0].toUpperCase() + splitwords[i].substr(1);
  }

  const joinedwords = splitwords.join(" ");
  console.log(joinedwords);
  return (
    <div>
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <div className="services-details">
              <Image src={NPTPG} />
              <p className="para-align-services">
                We protect the security of your network with our network
                penetration testing. We generate a complete cyber audit report
                on the network. On determining the OS and network
                vulnerabilities, we patch them. We report on every router,
                switch and port used. We assist in the upgradation to all latest
                versions and patching their vulnerabilities. We also recommend
                suitable cyber security protocol. All routers, firewalls and
                switches are tested for firmware updates. We frame firewall
                guidelines, if required.
              </p>
              <div className="choose-wrap-services serviceposter">
                <h2>Assessment Process</h2>
              </div>
              <h3>Data collation</h3>
              <p className="para-align-services">
                We initially gather information through our comprehensive survey
                of your network including architecture mapping and a complete
                network scan
              </p>
              <h3>Scanning</h3>
              <p className="para-align-services">
                We conduct a port scanning and war dialing that includes the
                scan of open ports, closed ports and filtered ports.
              </p>
              <h3>Fingerprinting</h3>
              <p className="para-align-services">
                We then proceed to conduct OS fingerprinting that includes
                evaluation of OS type, patch level and system type and protocol
                identification
              </p>
              <h3>Vulnerability check</h3>
              <p className="para-align-services">
                Further, we conduct a vulnerability scan using automated
                scanning with access to a vulnerability database, to verify
                vulnerabilities and exploits, if any.
              </p>
              <h3>Exploit verification</h3>
              <p className="para-align-services">
                Manual verification and password cracking are used to verify
                exploits. They are retested to validate results before report
                generation.
              </p>
              <h3>Reports</h3>
              <p className="para-align-services">
                Comprehension reports are generated to provide you the findings,
                suggested solutions and recommendations.
              </p>
            </div>
          </Col>
          <ServiceListContact joinedwords={joinedwords} />
        </Row>
        <h3 className="related-posts">Related Posts</h3>
        <Row>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${SOCJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Security Operations Centre
                  <br />
                  <br />
                </h3>
                <p>
                  This includes the collection and analysis of data to identify
                  suspicious activity and improve the
                </p>
                <a
                  className="read-more"
                  href="/services/security-operations-centre"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${VCCSOJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Virtual Chief Cyber Security Officer
                  <br />
                  <br />
                </h3>
                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>
                <a
                  className="read-more"
                  href="/services/virtual-chief-cyber-security-officer"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${APTJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Mobile Application Penetration Testing
                  <br />
                  <br />
                </h3>
                <p>
                  We perform a comprehensive audit on Android and IOS mobile
                  application to fix OWASP vulnerabilities.
                </p>
                <a
                  className="read-more"
                  href="/services/mobile-application-penetration-testing"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NetworkPen;
