import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutus/AboutUs";
import Services from "./pages/services/Services";
import Products from "./pages/products/Products";
import Blogs from "./pages/blogs/Blogs";
import Events from "./pages/events/Events";
import Careers from "./pages/careers/Careers";
import OurClients from "./pages/ourclients/OurClients";
import ContactUs from "./pages/contactus/ContactUs";
import WebSec from "./pages/services/services-pages/WebSec";
import RedTeaming from "./pages/services/services-pages/RedTeaming";
import MobilePen from "./pages/services/services-pages/MobilePen";
import NetworkPen from "./pages/services/services-pages/NetworkPen";
import VirtualCyber from "./pages/services/services-pages/VirtualCyber";
import SOC from "./pages/services/services-pages/SOC";
import BatchFileInstructions from "./pages/BatchFileInstructions";

import "./styles.css";
import HomeLayout from "./layouts/Home/HomeLayout";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="" element={<HomeLayout />}>
            <Route path="/" element={<Home />} />

            <Route path="about-us" element={<AboutUs />} />
            <Route path="services" element={<Services />} />
            <Route path="products" element={<Products />} />
            <Route path="blog" element={<Blogs />} />
            <Route path="events" element={<Events />} />
            <Route path="careers" element={<Careers />} />
            <Route path="our-clients" element={<OurClients />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="instructions" element={<BatchFileInstructions />} />
            <Route
              path="services/web-application-security-assessment"
              element={<WebSec />}
            />
            <Route path="services/red-teaming" element={<RedTeaming />} />
            <Route
              path="services/mobile-application-penetration-testing"
              element={<MobilePen />}
            />
            <Route
              path="services/network-penetration-testing"
              element={<NetworkPen />}
            />
            <Route
              path="services/virtual-chief-cyber-security-officer"
              element={<VirtualCyber />}
            />
            <Route
              path="services/security-operations-centre"
              element={<SOC />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
