import React from "react";
import "./BottomStyle.css";

const BottomFooter = () => {
  return (
    <>
      <div className="footer-bottom-area">
        <div className="content">
          <p>
            Copyright @2023 &nbsp;
            <a href="" target="blank">
              Necurity &nbsp;
            </a>
            | All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default BottomFooter;
