import React from "react";
import ServiceListContact from "../../../components/ServiceListContact/ServiceListContact";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import SOCJPG from "../../../assets/images/service-images/SOC.jpg";
import VCCSOJPG from "../../../assets/images/service-images/Virtual-Chief-Cyber-Security-Office.jpg";
import SAJPG from "../../../assets/images/service-images/Security-Assessment.jpg";
import APTJPG from "../../../assets/images/service-images/Application-Penetration-Testing.jpg";
import { useLocation } from "react-router-dom";
import "./WebSec.css";

function WebSec() {

  const location = useLocation();
  console.log(location);
  const { pathname } = location;
  const splitpath = pathname.split("/");
  const splitwords = splitpath[2].split("-");
  for (let i = 0; i < splitwords.length; i++) {
    splitwords[i] = splitwords[i][0].toUpperCase() + splitwords[i].substr(1);
  }

  const joinedwords = splitwords.join(" ");
  console.log(joinedwords);
  return (
    <div>
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <div className="services-details">
              <Image src={SAJPG} />
              <p className="para-align-services">
                Potential perpetration of cyber threats to your website is
                assessed through our website penetration testing. We generate a
                report on the complete security audit of the vulnerabilities of
                your web application, which would include details on the
                vulnerabilities, references and the method to fix them.
              </p>
              <p className="para-align-services">
                This cyber audit can be done monthly or once in 3 months, on
                requirement. On fixing the vulnerability, a remedial re-testing
                is done to ensure the safety of your website.
              </p>
              <p className="para-align-services">
                Your every digital update would require a cyber security audit.
                (e.g., the release of a PHP version or an Apache version). We
                also perform a vulnerability detection test on payment gateways,
                logical platform, social engineering, etc., and patch them.
              </p>
              <div className="choose-wrap-services serviceposter">
                <h2>Web App Threats</h2>
              </div>
              <h3>Injection Attacks</h3>
              <p className="para-align-services">
                These attacks (backdoors, weak algorithms and data storage
                definitions) are detected with comprehensive source code
                reviews, to free the interpreter of hostile data.
              </p>
              <h3>Cross site scripting (XSS) attacks</h3>
              <p className="para-align-services">
                To fix this, we unbox the application to understand user
                profiles, business case, functionality and the code base, if
                code review is commissioned. This relieves the web page of
                malicious scripts.
              </p>
              <h3>Patch Travel</h3>
              <p className="para-align-services">
                Faster patching of vulnerabilities is facilitated through the
                remedial guidance we provide your developers, which helps them
                focus better on product ingenuity.
              </p>
              <h3>Denial of Service</h3>
              <p className="para-align-services">
                To rectify this, we perform domain and platform-based tests to
                obtain a thorough understanding of the extent of the threat and
                fix it to re-enable user privileges, critical transactions and
                sensitive data security.
              </p>
              <p className="para-align-services">
                The fixing process is initiated with the comprehensive case
                profile we create to explore all vulnerabilities and threats. On
                obtaining client feedback we proceed to null the threats, by
                alleviating the host site of the malignant attack.
              </p>
              <h3>Request / Response smuggling attacks</h3>
              <p className="para-align-services">
                Fixing this allows you to receive real time updates of an
                ongoing project. Our testing experts can be contacted through a
                chat window to help you keep up your progress and avoid
                communication delays.
              </p>
            </div>
          </Col>
          <ServiceListContact joinedwords={joinedwords} />
        </Row>
        <h3 className="related-posts">Related Posts</h3>
        <Row>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${SOCJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Security Operations Centre
                  <br />
                  <br />
                </h3>
                <p>
                  This includes the collection and analysis of data to identify
                  suspicious activity and improve the
                </p>
                <a
                  className="read-more"
                  href="/services/security-operations-centre"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${VCCSOJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Virtual Chief Cyber Security Officer
                  <br />
                  <br />
                </h3>
                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>
                <a
                  className="read-more"
                  href="/services/virtual-chief-cyber-security-officer"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${APTJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Mobile Application Penetration Testing
                  <br />
                  <br />
                </h3>
                <p>
                  We perform a comprehensive audit on Android and IOS mobile
                  application to fix OWASP vulnerabilities.
                </p>
                <a
                  className="read-more"
                  href="/services/mobile-application-penetration-testing"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WebSec;
