import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

function Blogs() {
  const pageTitle = {
    title: "Blog",
    previous: "Home",
  };
  return (
    <>
      {/* <PageTitle title={pageTitle.title} previous={pageTitle.previous} /> */}
    </>
  );
}

export default Blogs;
