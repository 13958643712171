import React from "react";
import ServiceListContact from "../../../components/ServiceListContact/ServiceListContact";
import { useLocation } from "react-router-dom";
import APTJPG from "../../../assets/images/service-images/Application-Penetration-Testing.jpg";
import { Col, Row, Image, Container } from "react-bootstrap";
import SAJPG from "../../../assets/images/service-images/Security-Assessment.jpg";
import SOCJPG from "../../../assets/images/service-images/SOC.jpg";
import VCCSOJPG from "../../../assets/images/service-images/Virtual-Chief-Cyber-Security-Office.jpg";

function MobilePen() {
 
  const location = useLocation();
  console.log(location);
  const { pathname } = location;
  const splitpath = pathname.split("/");
  const splitwords = splitpath[2].split("-");
  for (let i = 0; i < splitwords.length; i++) {
    splitwords[i] = splitwords[i][0].toUpperCase() + splitwords[i].substr(1);
  }

  const joinedwords = splitwords.join(" ");
  console.log(joinedwords);
  return (
    <div>
      
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <div className="services-details">
              <Image src={APTJPG} />
              <p className="para-align-services">
                We perform a comprehensive audit on Android and IOS mobile
                application to fix OWASP vulnerabilities. Penetration testing
                for mobile application backend server is performed, to detect
                vulnerabilities and patch them. We provide 24×7 support in
                updating and patching of vulnerabilities on all mobile versions
                released occasionally.
              </p>
              <div className="choose-wrap-services serviceposter">
                <h2>Attributes</h2>
              </div>
              <h3>Threat profiling</h3>
              <p className="para-align-services">
                A threat profile listing all risks of your applications is
                created to enable testers to replicate relevant attacks.
              </p>
              <h3>Online Reporting</h3>
              <p className="para-align-services">
                To fix this, we unbox the application to understand user
                profiles, business case, functionality and the code base, if
                code review is commissioned. This relieves the web page of
                malicious scripts.
              </p>
              <h3>State-of-the-art lab</h3>
              <p className="para-align-services">
                We offer shortened testing cycles, apps validation on different
                devices and leverage of a repository of domain and platform
                specific test cases with our resourceful security testing labs.
                We offer a superior product, 45% sooner.
              </p>
              <h3>Multiple platforms</h3>
              <p className="para-align-services">
                We provide efficient testing through test apps like mobile
                banking, m-commerce and mobile payment systems on multiple
                device platforms that includes iPad, iPhone, Android,
                Blackberry, Symbian and Windows, under a single unified program,
                thus enhancing efficiency
              </p>
              <h3>Hybrid approach</h3>
              <p className="para-align-services">
                The fixing process is initiated with the comprehensive case
                profile we create to explore all vulnerabilities and threats. On
                obtaining client feedback we proceed to null the threats, by
                alleviating the host site of the malignant attack.
              </p>
              <h3>Experienced expert team</h3>
              <p className="para-align-services">
                Our team of dedicated security researches help you stay ahead of
                any trending threats in the mobile app world and provide app
                security through predictive intelligence.
              </p>
              <h3>Digital Marketing</h3>
              <p className="para-align-services">
                We facilitate better traffic and better sales-qualifying leads.
                We strive to give you an enhanced sale-oriented digital
                marketing service.
              </p>
              <h3>Brand Design</h3>
              <p className="para-align-services">
                We assure a better position of your brand in the digital market.
                With our efficient design, informative content and interactive
                style, your website further boosts your web presence, resulting
                in potential sales-convertible leads.
              </p>
              <h3>Native apps</h3>
              <p className="para-align-services">
                Our apps developed for a specific platform can interact with and
                benefit from the OS features or from any other software
                typically installed on that platform.
              </p>
              <h3>Wearable apps</h3>
              <p className="para-align-services">
                Customized for both Android and IOS devices, wearable apps can
                run directly on a watch. With sensors that enable efficient
                service as per your requirement, our wearable apps have a
                user-friendly interface.
              </p>
              <h3>Internet of Things</h3>
              <p className="para-align-services">
                We assess your varied requirements and formulate our objectives
                accordingly, to achieve them without compromising on quality.
                Our apps help your devices to interact and collaborate
                efficiently, to enrich your lifestyle.
              </p>
            </div>
          </Col>
          <ServiceListContact joinedwords={joinedwords} />
        </Row>
        <h3 className="related-posts">Related Posts</h3>
        <Row>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${SOCJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Security Operations Centre
                  <br />
                  <br />
                </h3>
                <p>
                  This includes the collection and analysis of data to identify
                  suspicious activity and improve the
                </p>
                <a
                  className="read-more"
                  href="/services/security-operations-centre"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${VCCSOJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Virtual Chief Cyber Security Officer
                  <br />
                  <br />
                </h3>
                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>
                <a
                  className="read-more"
                  href="/services/virtual-chief-cyber-security-officer"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div
              className="single-solutions"
              style={{ backgroundImage: `url(${SAJPG})` }}
            >
              <div className="solutions-content">
                <h3>
                  Web Application Security Assessment
                  <br />
                  <br />
                </h3>
                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>
                <a
                  className="read-more"
                  href="/services/web-application-security-assessment"
                >
                  Read more
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MobilePen;
