import React from "react";
import "./EventCard.css";

function EventCard({ card_info }) {
  return (
    <div className="flip">
      <div
        className="front  d-flex flex-column justify-content-center align-items-center font-barlow"
        style={{
          backgroundImage: `url(${card_info.image})`,
        }}
      >
        <h1 className="text-shadow">{card_info.title}</h1>
        <p>{card_info.subheading}</p>
      </div>
      <div className="back d-flex flex-column justify-content-center align-items-center">
        <i className="fa-solid fa-snowflake" style={{ color: "white" }}></i>
        <p>{card_info.description}</p>
      </div>
    </div>
  );
}

export default EventCard;
