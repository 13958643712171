import React from "react";
import "./RowLists.css";
import { Col, Row } from "react-bootstrap";
import { Reveal } from "react-awesome-reveal";

function RowLists() {
  const list1 = [
    "Enterprise network security",
    "Security for Cloud and Analytics",
    "Legacy modernization",
    "Incident response",
    "Patch management",
    "Business continuity management",
  ];

  const list2 = [
    "Security for Internet of things",
    "Securing mergers and acquisitions",
    "Managed Security",
    "Source code review",
    "Disaster recovery audit",
    "Firewall auditing",
  ];

  const list1items = list1.map((list, idx) => {
    return (
      <Reveal triggerOnce duration={800} delay={1*idx *100} key={`role_list_1_${idx}`}> 
      <div >
        
        <i className="bx bx-check"></i>
        {list}
       
      </div>
      </Reveal>
    );
  });

  const list2items = list2.map((list, idx) => {
    return (
      <Reveal triggerOnce duration={800} delay={(list1items.length *100) +  2*idx *100} key={`role_list_2_${idx}`}>
      <div >
        <i className="bx bx-check"></i>
        {list}
      </div>
      </Reveal>
    );
  });

  return (
    <Row >
      <Col md={6} >
        <div className="cybersecurity-item">{list1items}</div>
      </Col>

      <Col md={6}>
        <div className="cybersecurity-item">{list2items}</div>
      </Col>
    </Row>
  );
}

export default RowLists;
