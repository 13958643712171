import "./CustomModal.css";

function CustomModal({ client, index, onModalOpenClose, client_length }) {
  if (client === undefined) return;

  <></>;
  return (
    <div
      className={
        !(index === client_length)
          ? "inner-modal inner-modal-open"
          : "inner-modal"
      }
      style={{ backgroundColor: "#FFFFFF", color: client.color }}
    >
      <div
        className={
          !(index === client_length)
            ? "content-wrapper content-open"
            : "content-wrapper "
        }
      >
        <i
          className="fa-sharp fa-solid fa-xmark client-close-icon "
          style={{ color: client.color }}
          onClick={() => onModalOpenClose(client_length)}
        ></i>
        <div className="modal-content">
          <div className="model-image">
            <img
              src={client.front_img}
              alt={client.name}
              className="client-image"
            />
          </div>

          <div className="client-about">{client.about}</div>
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
