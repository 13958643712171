import React from "react";
import { Reveal, Slide } from "react-awesome-reveal";
import Container from "react-bootstrap/Container";
// import Button from "../Button/Button";
import backgroundBlinker from "../../assets/images/background/shape-1.png";
import "./HeroSection.css";
import { Button } from "react-bootstrap";

function HeroSection(props) {
  return (
    <Container
      fluid
      className="banner"
      style={{
        backgroundImage: `url(${props.src})`,
      }}
    >
      {/* <img className="banner " src={props.src} alt="banner" /> */}
      <div
        className="blink"
        style={{ backgroundImage: `url(${backgroundBlinker})` }}
      ></div>

      <div className="bannerText text-center d-flex flex-column justify-content-center align-items-center">
        <h1>
          <Reveal triggerOnce duration={1500} delay={0}>{props.title}</Reveal>
        </h1>
        <p style={{width:"70%"}}>
          <Reveal triggerOnce delay={1000} duration={1500} >{props.desc}</Reveal>
        </p>
        <div className="d-flex  justify-content-center">
          <Reveal triggerOnce  duration={1000} delay={0}>
            <Button
              variant="custom-transparent"
              className="btn-custom m-2 "
              value="other"
            >
              <a href="/contact-us">Get Secured</a>
            </Button>
          </Reveal>

          <Reveal triggerOnce  duration={1000} delay={0}>
            <Button
              variant="custom-transparent"
              className="btn-custom-white m-2 "
              value="about"
            >
              <a href="/about-us">About Us</a>
            </Button>
          </Reveal>
          <div>
         <a href="#feature-section">
         <svg className="arrows" >
							<path className="a1" d="M0 0 L30 32 L60 0"></path>
							<path className="a2" d="M0 20 L30 52 L60 20"></path>
							<path className="a3" d="M0 40 L30 72 L60 40"></path>
						</svg>
         </a>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default HeroSection;
