import React from "react";
import { Card, Container } from "react-bootstrap";
import "./CardContainer.css";

function CardContainer() {
  const visions = [
    {
      id: 1,
      title: "Cyber Security",
      desc1:
        "The ever-evolving IT environment emphasizes the necessity of cybersecurity to avoid vulnerability of data systems. Internet network has shrunk the world. Communication has become easier and faster.Hence, the far-reaching internet community demands a better and efficient cybersecurity system in place. Technology has assisted in the sophistication of the cyber threats too. Such adept attacks require an equally proficient testing and fixing security system to locate and eventually eliminate them.",
      desc2:
        "With a vision to provide net security as an asset to the business industry and to help it avail the entire advantages of information technology, Necurity Solutions ventured into cyber safety in the year 2016. The company has extended its security service to various industries like hospitality, shipping, medicine, jewellery, etc.",
    },
  ];

  const visionList = visions.map((vision, idx) => {
    return (
      <Card.Body className="visions-body" key={`vision_${idx}`}>
        <Card.Title style={{ textAlign: "center" }}>
          {vision.title}
        </Card.Title>
        <Card.Text>{vision.desc1}</Card.Text>
        <Card.Text>{vision.desc2}</Card.Text>
      </Card.Body>
    );
  });

  return (
    <Container>
      <Card className="vision-card">{visionList}</Card>
    </Container>
  );
}

export default CardContainer;
