import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import ContactForm from "../../components/Contact-Form/ContactForm";
import ContactImageJPG from "../../assets/images/contact-image.jpg";
import "./ContactUs.css";

function ContactUs() {
 
  return (
    <>

      <Container>
        <Col className="m-5">
          <Row className="text-center">
            <h4 className="contact-title">Contact Us</h4>
          </Row>
          <Row className="text-center">
            <h1 className="contact-description">
              Drop Us A Message For Any Query
            </h1>
          </Row>
        </Col>

        <Row className=" align-items-md-center">
          <Col xs={12} md={6} style={{ height: "40em" }}>
            <Image
              className="w-100 h-100 img-fluid"
              src={ContactImageJPG}
              style={{ backgroundSize: "cover" }}
              rounded
            />
          </Col>
          <Col xs={12} md={6} style={{ height: "40em" }}>
            <Card className="rounded-0  w-100 h-100">
              <Card.Body>
                <ContactForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
