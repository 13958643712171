import React from "react";
import { Slide } from "react-awesome-reveal";
import HeroSection from "../../components/Hero/HeroSection";
import Features from "../../components/Features/Features";
import CardContainer from "../../components/CardContainer/CardContainer";
import SingleCard from "../../components/MissionCard/SingleCard";
import DualColumn from "../../components/DualColumn/DualColumn";
import CarouselServices from "../../components/CarouselServices/CarouselServices";
import ExpertiseSection from "../../components/ExpertiseSection/ExpertiseSection";

import BannerJPG from "../../assets/images/banner.jpg";
import HomeAboutJPG from "../../assets/images/home-about.png";
import AboutLeftJPG from "../../assets/images/aboutLeft.png";
import AboutRightJPG from "../../assets/images/aboutRight.png";

const hero = {
  id: 1,
  title: "Cyber Security Is Not An Option anymore",
  desc: "The ever-evolving IT environment emphasizes the necessity of cybersecurity to  avoid vulnerability of data systems. Internet network has shrunk the world.",
  src: BannerJPG,
};

const about = {
  id: 1,
  title: "About Us",
  desc: "Necurity Solutions is a holistic information technology security solution provider .We focus on providing innovative security solutions, to ensure the value of your digital assets.We do this through our application security (web & mobile), network security (enterprise & IT), data protection (security & theft) , protection against malware / ransomware / bots and cloud security.",
  aboutSrc: HomeAboutJPG,
  progressTitle: "Security",
  progressPerc: "98%",
  aboutSrcLeft: AboutLeftJPG,
  aboutSrcRight: AboutRightJPG,
};

function Home() {
  return (
    <>
      <HeroSection title={hero.title} desc={hero.desc} src={hero.src} />
      <Features />
      <CardContainer />
      
      <SingleCard />

      <DualColumn
        aboutSrc={about.aboutSrc}
        title={about.title}
        desc={about.desc}
      />

      <CarouselServices />

      
        <ExpertiseSection />
      
    </>
  );
}

export default Home;
