import React from "react";
import ImageTitleDesc from "../../components/ImageTitleDesc/ImageTitleDesc";
import PopoutOnClick from "../../components/PopoutOnClick/PopoutOnClick";
import NaviArcaPNG from "../../assets/images/product-images/NAVIS-ARCA.png";
import "./Products.css";

function Products() {
  
  // Services
  const servicesRow1 = {
    icon1: "fas fa-desktop",
    title1: "CYBER AUDIT REPORT",
    desc1: "Complete Cyber Audit report on the Network.",
    icon2: "fas fa-chart-bar",
    title2: "REPORT",
    desc2: "Complete report on the routers, switches, and ports that are used.",
  };
  const servicesRow2 = {
    icon1: "fas fa-external-link-alt",
    title1: "UPGRADE",
    desc1:
      "Upgrading to all latest versions and patching of the all the vulnerabilities.",
    icon2: "fas fab fa-searchengin",
    title2: "TESTING",
    desc2: "Complete pen testing after the vulnerabilities is patched.",
  };
  const servicesRow3 = {
    icon1: "fas fa-user-lock",
    title1: "VULNERABILITIES",
    desc1: "Determining OS and network vulnerabilities and patching them.",
    icon2: "fas far fa-snowflake",
    title2: "FIREWALL",
    desc2: "Framing firewall rules if necessary.",
  };
  const servicesRow4 = {
    icon1: "fas fa-lock",
    title1: "SECURITY",
    desc1: "Cyber Security Recommendations",
    icon2: "fas fa-sync",
    title2: "UPDATES",
    desc2:
      "Checking each Routers, Firewalls, Switches for latest firmware updates.",
  };
  // Features
  const features1 = {
    icon1: "fas fa-desktop",
    title1: "Remote",
    desc1: "Works remotely, anywhere, anytime.",
    icon2: "fas fa-camera",
    title2: "Performance",
    desc2: "Optimized for remote access to avoid delay in response",
  };
  const features2 = {
    icon1: "fas fa-external-link-alt",
    title1: "Backend",
    desc1: "Runs a customized operating system to suit the needs of expert.",
    icon2: "fas fa-tools",
    title2: "Tools",
    desc2: "Complete suite of tools required by the cyber experts.",
  };
  const features3 = {
    icon1: "fab fa-megaport",
    title1: "Portable",
    desc1: "Compact form factor to make it portable to fit in small places.",
    icon2: "fas fa-download",
    title2: "Simple Installation Process",
    desc2:
      "Navis Arca has only a two stem installation process.\nI. Plug-in to the power source.\nII. Connect the ethernet cable to the network.",
  };

  const methods1 = {
    title: "INFORMATION GATHERING",
    desc: "The Network Penetration Process begins with a comprehensive survey of your network including architecture mapping and a complete network scan",
  };
  const methods2 = {
    title: "SCANNING",
    desc: "The testing process continues with port scanning that includes scanning open ports, closed ports, and filtered ports.",
  };
  const methods3 = {
    title: "OUR SERVICES",
    desc: "A vulnerability scan is completed using automated scanning with access to a vulnerability database, where any vulnerabilities and exploits are verified.",
  };
  const methods4 = {
    title: "EXPLOIT VERIFICATION",
    desc: "Using manual verification and password cracking, available exploits are checked and retested if necessary, to validate results before reports are produced.",
  };
  const methods5 = {
    title: "REPORT GENERATION",
    desc: "On conclusion of a network penetration test, comprehensive reports are created to provide findings, suggest solutions, and make recommendations.",
  };
  const methods6 = {
    title: "DEVELOP PROTECTION AND DETECTION MEASURES",
    desc: "To reduce the likelihood and the impact of a potential exploitation of a vulnerability.",
  };
  const methods7 = {
    title: "ESTABLISH PRIORITIZED CONTINGENCY PLANS",
    desc: "To mitigate any potential identified cyber risk",
  };
  const methods8 = {
    title: "RESPOND AND RECOVERS",
    desc: "From cyber incidents using the contingency plan to ensure operational continuity.",
  };

  return (
    <>
      <div className="container">
        <div className="section-title">
          <h2>
            NAVIS ARCA, MARITIME CYBERSECURITY PRODUCT <br /> <br />
          </h2>
          <h4>THE IMPORTANCE OF NAVIS ARCA ON INTERNATIONAL SHIPPING</h4>
        </div>
      </div>
      <div className="mission">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="electronic-content">
              <h2></h2>
              <div className="electronic-tab-wrap">
                <div className="tab electronic-tab">
                  <ul className="tabs active">
                    <li className="current">
                      <a href="#">Who we are? </a>
                    </li>
                  </ul>
                  <div className="tab_content">
                    <div className="tabs_item">
                      <p className="para-align">
                        NAVIS ARCA is an IOT based cyber security solution for
                        ships across the world. It allows the cyber security
                        experts to remotely connect to the vessel’s network and
                        perform vulnerability assessment and penetration testing
                        without being physically present in the vessel.Marine
                        Cyber Vulnerability has been handpicked from various
                        backgrounds to resolve marine cyber threats in
                        compliance to IMO 428 Resolution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="electronic-img">
              <img className="navis" src={NaviArcaPNG} alt="Image" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section-title navis-product">
          <h2>
            ROLE OF CYBERSECURITY:
            <br /> <br />
          </h2>
          <p>
            Cyber Security becomes an inevitable topic in both maritime &
            offshore industries. Maritime is mainly based on software &
            network-based operations. <br />
            <br /> Cyber systems on ships are classified as either IT (standard
            information systems) or OT (operation and control
            systems).Cyber-attacks on maritime networks will result in high loss
            for shipping industries.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="section-title navis-product">
          <h2>
            WHY NAVIS ARCA?
            <br /> <br />
          </h2>
          <p>
            NAVIS ARCA provides 99.9% security from all kinds of cyber-attacks
            by consuming less power, without any supervision on vessel and it
            can access completely on remote by our dedicated team of Cyber
            Security Experts. Moreover, we are securing on the basis of IMO
            resolutions.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="section-title navis-product">
          <h2>
            OUR SERVICES
            <br /> <br />
          </h2>
        </div>
      </div>
      <div className="container">
        <ImageTitleDesc
          icon1={servicesRow1.icon1}
          title1={servicesRow1.title1}
          desc1={servicesRow1.desc1}
          icon2={servicesRow1.icon2}
          title2={servicesRow1.title2}
          desc2={servicesRow1.desc2}
        />
        <ImageTitleDesc
          icon1={servicesRow2.icon1}
          title1={servicesRow2.title1}
          desc1={servicesRow2.desc1}
          icon2={servicesRow2.icon2}
          title2={servicesRow2.title2}
          desc2={servicesRow2.desc2}
        />
        <ImageTitleDesc
          icon1={servicesRow3.icon1}
          title1={servicesRow3.title1}
          desc1={servicesRow3.desc1}
          icon2={servicesRow3.icon2}
          title2={servicesRow3.title2}
          desc2={servicesRow3.desc2}
        />
        <ImageTitleDesc
          icon1={servicesRow4.icon1}
          title1={servicesRow4.title1}
          desc1={servicesRow4.desc1}
          icon2={servicesRow4.icon2}
          title2={servicesRow4.title2}
          desc2={servicesRow4.desc2}
        />
      </div>
      <div className="container">
        <div className="section-title navis-product">
          <h2>
            OUR FEATURES
            <br /> <br />
          </h2>
          <p>
            NAVIS ARCA ensures maritime cyber security through our pre-loaded
            customized vulnerability tools which helps to identify all types of
            existing vulnerabilities globally. NAVIS ARCA act as your maritrime
            cyber security partner of your ship which handles all your cyber
            security issues by its unique features such as:
          </p>
        </div>
      </div>
      <div className="container">
        <ImageTitleDesc
          icon1={features1.icon1}
          title1={features1.title1}
          desc1={features1.desc1}
          icon2={features1.icon2}
          title2={features1.title2}
          desc2={features1.desc2}
        />
        <ImageTitleDesc
          icon1={features2.icon1}
          title1={features2.title1}
          desc1={features2.desc1}
          icon2={features2.icon2}
          title2={features2.title2}
          desc2={features2.desc2}
        />
        <ImageTitleDesc
          icon1={features3.icon1}
          title1={features3.title1}
          desc1={features3.desc1}
          icon2={features3.icon2}
          title2={features3.title2}
          desc2={features3.desc2}
        />
      </div>
      <div className="container">
        <div className="section-title">
          <h2>WORKING METHODOLOGY</h2>
        </div>
      </div>
      <div className="container">
        <PopoutOnClick title={methods1.title} desc={methods1.desc} />
        <PopoutOnClick title={methods2.title} desc={methods2.desc} />
        <PopoutOnClick title={methods3.title} desc={methods3.desc} />
        <PopoutOnClick title={methods4.title} desc={methods4.desc} />
        <PopoutOnClick title={methods5.title} desc={methods5.desc} />
        <PopoutOnClick title={methods6.title} desc={methods6.desc} />
        <PopoutOnClick title={methods7.title} desc={methods7.desc} />
        <PopoutOnClick title={methods8.title} desc={methods8.desc} />
      </div>
    </>
  );
}

export default Products;
