import React from "react";
import OurClientCard from "../../components/OurClientCard/OurClientCard";
import { Container } from "react-bootstrap";

function OurClients() {
  const pageTitle = {
    title: "Our Clients",
    previous: "Home",
  };
  return (
    <>
      <Container >
        <div className="d-flex justify-content-center justify-items-center align-items-center">
          <OurClientCard />
        </div>
      </Container>
    </>
  );
}

export default OurClients;
