import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import RowLists from "../RowLists/RowLists";
import ExpertiseJPG from "../../assets/images/Expertise-col-1.jpg";
import "./ExpertiseSection.css";

function ExpertiseSection() {
  return (
    <Container>
      <Row>
        <Col lg={true}>
          <Image
            className="security-image img-fluid"
            src={ExpertiseJPG} 
          />
        </Col>
        <Col className="mt-4" lg={true}>
          <h2>The Necurity Expertise</h2>
          <p className="expertise-text">
            The broader spectrum of our service involves Audit reports, Incident
            response, Advanced testing, Threat research, Cloud security and
            Design & development. Besides Web app penetration testing, Mobile
            app penetration testing and Network penetration testing, our areas
            of expertise cover the following:
          </p>
          <RowLists />
        </Col>
      </Row>
    </Container>
  );
}

export default ExpertiseSection;
