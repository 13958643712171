import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EventCard from "../../components/Event-Card/EventCard";
import EventImage1 from "../../assets/images/event-images/event-1.png";
import EventImage2 from "../../assets/images/event-images/event-2.png";
import EventImage3 from "../../assets/images/event-images/event-3.png";

const event_list = [
  {
    title: "Event 1",
    subheading: "Event Content",
    description: "Here fill the content",
    image: EventImage1,
  },
  {
    title: "Event 2",
    subheading: "Event Content",
    description: "Here fill the content",
    image: EventImage2,
  },
  {
    title: "Event 3",
    subheading: "Event Content",
    description: "Here fill the content",
    image: EventImage3,
  },
];

function Events() {

  return (
    <>
      <Container className=" d-flex flex-wrap justify-content-center align-items-center">
        <Row style={{ width: "100%" }}>
          {event_list.map((event, idx) => {
            return (
              <Col
                key={`event_${idx}`}
                sm={12}
                md={4}
                style={{ minWidth: "33.3%" }}
              >
                <EventCard card_info={event} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default Events;
