import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import "./Image-Column.css";

function ImageColumn(props) {
  return (
    <Container>
      <Row>
        <Col lg={true}>
          <Image
            className="w-100 h-100 img-fluid"
            style={{ backgroundSize: "cover" }}
            rounded
            src={props.src}
            alt="Image"
          />
        </Col>
        <Col lg={true} className="aboutus">
          <h2>{props.title}</h2>
          <p>{props.desc}</p>
          <ul className="about-list">
            <li>
              <i className="bx bx-check-shield"></i>
              <h3>Assurance</h3>

              <p>Assured alleviation of clients’ issue.</p>
            </li>
            <li>
              <i className="bx bx-lock"></i>
              <h3>Integrity</h3>
              <p>Genuine security process.</p>
            </li>
            <li>
              <div>
                <i className="bx bx-certification"></i>
                <h3>Complete Cyber Security</h3>
              </div>
              <p>Ensured safety of your web / network.</p>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default ImageColumn;
