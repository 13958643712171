import React from "react";
import "./ProgressBar.css";
import { Col, Container, Row } from "react-bootstrap";

function ProgressBar() {
  return (
    <Container>
      <Row className="perc">
        <Col>
          <h4>Security</h4>
        </Col>
        <Col>
          <p className="perGap">98%</p>
        </Col>
      </Row>
      <div className="progress-bar">
        <div className="progress-content"></div>
      </div>
    </Container>
  );
}

export default ProgressBar;
