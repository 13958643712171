import { React, useEffect, useRef } from "react";
import "./TopBar.css";
import { useLocation } from "react-router-dom";
function TopBar({ location }) {
   
 
  const ref = useRef(null);

  useEffect(() => {
  
    ref.current.style.height = location.pathname === "/" ? "0px" : "60px";
  }, [location]);


  return (
    <div className="top-bar" ref={ref}>
      <div className="container-fluid text-light">
        <div className="d-flex flex-wrap justify-content-between mx-5 mt-3 align-items-center justify-items-center">
          <ul className="d-flex flex-wrap contact-links justify-content-center">
            <li>
              <a href="mailto:support@necurity.com">
                <i className="bx bx-envelope"></i>
                &nbsp; &nbsp; support@necurity.com
              </a>
            </li>
            <li>
              <i className="bx bx-location-plus"></i>
              &nbsp;&nbsp; Chennai, Tamilnadu
            </li>
          </ul>

          <ul className="d-flex flex-wrap contact-links  justify-content-center">
            <li>
              <a
                target="_blank"
                href="https://twitter.com/necuritysoln?s=11"
                className="twitter"
                rel="noreferrer"
              >
                <i className="bx bxl-twitter"></i>
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/necuritysoln/"
                className="facebook"
                rel="noreferrer"
              >
                <i className="bx bxl-facebook"></i>
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/necuritysoln/"
                rel="noreferrer"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
