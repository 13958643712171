import React from "react";
import "./ImageTitleDesc.css";

function ImageTitleDesc(props) {
  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <li className="product-image">
            <i className={`services-icon ${props.icon1}`}></i>
            <div className="services-text">
              <h3>{props.title1}</h3>
              <p>{props.desc1}</p>
            </div>
          </li>
        </div>
        <div className="col-lg-6">
          <li className="product-image">
            <i className={`services-icon ${props.icon2}`}></i>
            <div className="services-text">
              <h3>{props.title2}</h3>
              <p>{props.desc2}</p>
            </div>
          </li>
        </div>
      </div>
    </div>
  );
}

export default ImageTitleDesc;
