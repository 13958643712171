import React from "react";
import "./Footer.css";
import BottomFooter from "./BottomFooter";
// import footer from "./footer-bg.jpg";
function Footer() {
  return (
    <>
      <footer className="footer-top-area ">
        <div className="container footer-container">
          <div className="row footer-row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget widget_text">
                <h3>Contact Info</h3>
                <div className="textwidget">
                  <ul className="contact_links">
                    <li>
                      <i className="bx bx-location-plus"></i>
                      {/* <span className="span">Address:</span> */}
                      <b>Tamil Nadu </b>
                      <br></br>Block 3, 2nd Floor,6/13, North Avenue,
                      Kesavaperumalpuram, Equinox Managed Workspaces, Raja
                      Annamalai Puram, Tamil Nadu. Chennai &#8211; 600028
                    </li>
                    <li>
                      <i className="bx bx-location-plus"></i>
                      {/* <span className="span">Address:</span> */}
                      <b>Kerala</b> <br></br>2nd floor, Aspinwall Building,
                      Kowdiar – Kuravankonam Road, Trivandrum &#8211; 695003,
                      Kerala.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget widget_nav_menu">
                <h3>Services Link</h3>
                <div className="menu-services-links-container">
                  <ul id="menu-services-links" className="menu">
                    <li
                      id="menu-item-373"
                      className="menu-item menu-item-type-post_type menu-item-object-service menu-item-373"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/services/network-penetration-testing">
                        Network Penetration Testing
                      </a>
                    </li>
                    <li
                      id="menu-item-374"
                      className="menu-item menu-item-type-post_type menu-item-object-service menu-item-374"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/services/mobile-application-penetration-testing">
                        Mobile Application Penetration Testing
                        <br />
                        <br />
                      </a>
                    </li>
                    <li
                      id="menu-item-375"
                      className="menu-item menu-item-type-post_type menu-item-object-service menu-item-375"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/services/web-application-security-assessment">
                        Web Application Security Assessment
                        <br />
                        <br />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget widget_nav_menu">
                <h3>Quick Support</h3>
                <div className="menu-support-links-container">
                  <ul id="menu-support-links" className="menu">
                    <li
                      id="menu-item-382"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-382"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="single-footer-widget widget_text">
                <div className="textwidget">
                  <ul className="contact_links supt_lnk">
                    <li>
                      <i className="bx bx-phone-call"></i>
                      <a href="tel:+9186101-40034"> +91-8610 14 0034 </a>
                    </li>

                    <li>
                      <i className="bx bx-envelope"></i>
                      <a href="mailto:support@necurity.com">
                        support@necurity.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget widget_nav_menu">
                <h3>Quick Links</h3>
                <div className="menu-quick-links-container">
                  <ul id="menu-quick-links" className="menu">
                    <li
                      id="menu-item-386"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-386"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/about-us">About</a>
                    </li>
                    <li
                      id="menu-item-387"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-387"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/services">Services</a>
                    </li>
                    <li
                      id="menu-item-871"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-871"
                    >
                      <i className="bx bx-chevrons-right"></i>
                      <a href="/careers">Careers</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomFooter />
      </footer>
    </>
  );
}

export default Footer;
