import AVTPNG from "../../assets/images/client logo PNG/AVT.png";
import BRFPNG from "../../assets/images/client logo PNG/BRF.png";
import BIPLPNG from "../../assets/images/client logo PNG/Brakes india.png";
import CENZAPNG from "../../assets/images/client logo PNG/CENZA.png";
import CALPNG from "../../assets/images/client logo PNG/CCAL.png";
import FLPLPNG from "../../assets/images/client logo PNG/FLYJAC.png";
import HEIDMARPNG from "../../assets/images/client logo PNG/HEIDMAR.png";
import HUBILOSVG from "../../assets/images/client logo PNG/HUBLIO.png";
import INFINITHEISMPNG from "../../assets/images/client logo PNG/INFINITHEISM.png";
import KARIXPNG from "../../assets/images/client logo PNG/KARIX.png";
import LEGALEASEPNG from "../../assets/images/client logo PNG/LEGALEASE.png";
import NEWRYPNG from "../../assets/images/client logo PNG/NEWRY.png";
import RBAPNG from "../../assets/images/client logo PNG/RBAC.png";
import RKIPNG from "../../assets/images/client logo PNG/R.K.INDUSTRIES.png";
import RXILPNG from "../../assets/images/client logo PNG/RXIL.png";
import SAMSPNG from "../../assets/images/client logo PNG/SAMS.png";
import SIMSJPG from "../../assets/images/client logo PNG/SARASWATHI.png";
import SICGILJPG from "../../assets/images/client logo PNG/SICGIL.png";
import SOLVERMINDSSVG from "../../assets/images/client logo PNG/SOLVERMINDS.png";
import STENHOUSEPNG from "../../assets/images/client logo PNG/RECREATE.png";
import TECHINDIAPNG from "../../assets/images/client logo PNG/TECHINDIA.png";
import TEAMPNG from "../../assets/images/client logo PNG/TEAM.png";
import TTSPLPNG from "../../assets/images/client logo PNG/TTSPL.png";
import TVSPNG from "../../assets/images/client logo PNG/TVS TYRES.png";
import VTIREFPNG from "../../assets/images/client logo PNG/VT.png";
import VRIDHIPNG from "../../assets/images/client logo PNG/VIRDHIMAR.png";

import GRTPNG from "../../assets/images/client logo PNG/GRT.png";
import MATRIXPNG from "../../assets/images/client logo PNG/MATRIX.png";
import RAMOJIPNG from "../../assets/images/client logo PNG/RAMOJI.png";
import HIMTPNG from "../../assets/images/client logo PNG/HIMT.png";
import NSEPNG from "../../assets/images/client logo PNG/NSE.png";
import CUBPNG from "../../assets/images/client logo PNG/CUB.png";

export const clients_list = [
  {
    name: "AV Thomas & CO. LTD.,",
    description: "Plantation",
    front_img: AVTPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing"],
    about: `AVT (A. V. Thomas Group) is a professionally managed group of companies with its headquarters in Chennai, India. It ventured into multiple new business areas to meet rapidly changing needs in India and beyond. In addition to plantations, the company's portfolio now includes Consumer Products, Natural Food Ingredients, Nutraceuticals and Leather Products.It has forged a strong, reliable and trusting partnerships with some of the biggest international names in the industry, such as McCormick, Gavia and Kemin Industries.`,
  },
  {
    name: "Bioscience Research Foundation",
    description:
      "Integrated discovery and developing Contract Research Organization",
    front_img: BRFPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["research"],
    about: `Bioscience Research Foundation (BRF) is an integrated discovery and developing Contract Research Organization(CRO).It is a eco-friendly test facility with modern equipped analytical instruments.GLP(Good Laboratory Practice) certified laboratory (NGCMA - National GLP Compliance Monitoring Authority), Government of India.`,
  },
  {
    name: "Brakes India Private Limited",
    description: "Largest brake system supplier in India",
    front_img: BIPLPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing"],
    about: `Headquartered in Chennai, Brakes India Private Limited has three main divisions; Brake division, Foundry division and Polymer division. Brakes India Private Limited is the largest component manufacturing company within the TVS – Santhanam group. The comprehensive product portfolio includes calipers, actuation, drum brakes, valves, hose ABS and brake fluid for passenger vehicles, S-cam, Hydraulic drum brakes, Disk brakes and Electro Magnetic Retarders for commercial vehicles and Dry and Wet multiple plate disk brakes for agricultural tractors.`,
  },
  {
    name: "Chemfab Alkalis Limited",
    description: "Holistic well-being",
    front_img: CALPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["science"],
    about: `CCAL, established by Dr. Rao in 1985, has many ‘first’ to its credit and has received numerous patents and worldwide recognition for the viability and implementation of various innovative technologies in the Chloralkali sector.Chemfab Alkalis Limited (CCAL) has established the first membrane cell plant for Chloralkali production in India; it has also built a long legacy of sustainability and diversity in the workplace. CCAL is one of the first major chemical organisations in India to introduce and implement innovative technologies successfully, and stay at the forefront of the industry.`,
  },

  {
    name: "Flyjac Logistics",
    description: "Commitment to customer satisfaction",
    front_img: FLPLPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["logistics"],
    about: `Flyjac Logistics is headquartered in Chennai in southern India. It is led by a team of highly experienced managers, supervisors and frontline workforce that is well-versed with the industry as well as with its ever-evolving dynamic shifts.Since its inception in 1990, Flyjac Logistics has grown to a logistics leader with a global footprint.`,
  },
  {
    name: "Heidmar Inc.",
    description: "Innovation, Relationships, Performance & Transparency",
    front_img: HEIDMARPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["management"],
    about: `Heidmar  is the original pool manager in the tanker space, it understands the need for a consolidated and economical approach to the tanker market.eFleetWatch provides pool partners with access to completely transparent access to real-time, un-scrubbed, data that they require for their own reporting and monitoring of their vessels.`,
  },
  {
    name: "Hubilo Softech",
    description: "One platform. Any event.",
    front_img: HUBILOSVG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["technology"],
    about: `
    Hubilo is the event tech platform and it helps help event planners and organizations across the world reimagine the future of events and navigate the ever-changing event landscape.Hubilo provides a virtual venue for organizations to provide an engaging and interactive event experience to their global audience and transform the way people connect, interact, and host events.`,
  },
  {
    name: "Infinitheism Spiritual Foundation Pvt Ltd",
    description: "with Mahatria",
    front_img: INFINITHEISMPNG,
    background_color: "#ffffff",
    color: "#000000",
    category: ["spiritual"],
    about: `
    Infinitheism Spiritual Foundation Private Limited is a Private incorporated on 23 November 2011. It is classified as Non-govt company and is registered at Registrar of Companies, Chennai. Mahatria‘s wisdom page is created with curated wisdom by Mahatria, to provide you clarity and put you on a fast track.`,
  },
  {
    name: "Karix Mobile Private Limited",
    description: "Connect & interact",
    front_img: KARIXPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["technology"],
    about: `Karix Mobile empowers enterprises to use mobile to transform their business at all stages of customer life cycle, from acquisition to retention. karix enable enterprises to intelligently personalize their two-way marketing & customer care interactions, across multiple channels through our robust, secure and scalable mobile engagement platform.`,
  },
  {
    name: "Cenza Technologies Pvt Ltd",
    description: "Legal Tech",
    front_img: CENZAPNG,
    background_color: "#ffffff",
    color: "#000000",
    category: ["technology"],
    about: `Cenza Technologies services include Contract Migration, Legal AI/ML Training, Legal Secretarial Services, Real Estate Lease Management, and CLM Migration & Implementation.Cenza delivers scalable, cost-effective and reliable outsourcing services built on best-in-class technology, operational expertise, rigorous quality control and unsurpassed client service. Cenza provide a consultative approach and provide a range of managed services to banks, law firms, consulting firms and document management companies in the U.S., U.K., Canada and Australia.`,
  },
  {
    name: "Newry Properties Private Limited",
    description: "Your dream our commitment",
    front_img: NEWRYPNG,
    background_color: "#ffffff",
    color: "#000000",
    category: ["civil"],
    about: ` 
    Newry Properties Private Limited - Chennai, (formerly Sonex Realty), is a part of the well known Hongkong based NEWRY GROUP. Newry at Chennai having property development as its core portfolio is a synonyms name in the property development domain, since 1993. An acclaimed professionally managed organization, with stable, unique business model and sustained growth, NEWRY has created a brand name that stands for unflinching Integrity, Reliability and Transparent business practices.`,
  },
  {
    name: "LegalEase Solutions LLC",
    description: "Delivering Tech-powered Solutions with Ease",
    front_img: LEGALEASEPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["technology"],
    about: `LegalEase Solutions is a legal solutions provider with over 16 years of expertise in offering corporate legal departments innovative technology-enabled services.Offering a full range of legal services ranging from contract review, contract lifecycle management, predictive analytics, legal operations support, legal intelligence, litigation support and regulatory compliance solutions, LegalEase Solutions utilizes cutting-edge technology, process engineering, and right-sourcing.`,
  },

  {
    name: "R.K. INDUSTRIES-IV",
    description: "Leading apparels manufacturer & exporter from India",
    front_img: RKIPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["export", "manufacturing"],
    about: `R.K Industries was established in 1973 in Chennai India.It's fully integrated providing yarn dye dyeing, weaving, garment manufacturing, printing, embroidery and washing services.Recognizing the importance of providing its customers with the latest and innovative design trends R.K. Industries has a Design and Sales office in New York for more international reach. Our long standing relationship with our customers is a testimony of our service and commitment.`,
  },
  {
    name: "RBA Exports Private Limited",
    description: "Largest manufacturer exporter of iron castings from India",
    front_img: RBAPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["export", "manufacturing"],
    about: `Based in Kolkata, West Bengal, RBA Exports Private Limited was founded in the year 1958 as a trading house. It has grown from being a trading company dealing in wire nail, paper and other commodities to being the largest manufacturer exporter of iron castings from India.`,
  },
  {
    name: "Receivables Exchange of India Limited",
    description:
      "Joint venture promoted by Small Industries Development Bank of India",
    front_img: RXILPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["finance", "business"],
    about: `Receivables Exchange of India Ltd (RXIL) was incorporated on February 25, 2016 as a joint venture between Small Industries Development Bank of India (SIDBI) – the apex financial institution for promotion and financing of MSMEs in India and National Stock Exchange of India Limited (NSE) - premier stock exchange in India. RXIL operates the Trade Receivables Discounting System (TReDS) Platform as per the TReDS guideline issued by Reserve Bank of India (RBI) on December 3, 2014.
 `,
  },
  {
    name: "SAMS Kappal College",
    description: "Born out of dream from a Mariner",
    front_img: SAMSPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["educational"],
    about: `SAMS – Southern Academy of Maritime Studies was born out of dream from a Mariner, the Chairman of the Group. The Institute is 23 years old.SAMS is one of the premier Marine Institutes in the country, offering various Marine courses.SAMS has been adjudged as a very good marine college in India by The European Maritime Safety Association (EMSA) an organ of International Maritime Organization. The courses offered in the Institute are approved by Directorate General of Shipping, Ministry of Shipping, Government of India and are affiliated to leading Universities like Anna University, Alagappa University, Indian Maritime University,etc.`,
  },
  {
    name: "Saraswathi Institute of Medical Sciences",
    description: "In the field of medical education and research",
    front_img: SIMSJPG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["educational", "research"],
    about: `
    Saraswathi Institute of Medical Sciences (SIMS),affiliated to Chaudhary Charan Singh (CCS) University, Meerut, Uttar Pradesh, is a renowned and recognized institute in the field of medical education and research. Established in 2008,SIMS got approval from Medical Council of India/Government of India to commence MBBS course. SIMS has also obtained permission to commence PG Courses from 2013-14.SIMS has been awarded ‘Best Medical College in Delhi-NCR’ by Brands Academy in March 2012.`,
  },
  {
    name: "SICGIL Industrial Gases Limited",
    description: "Most preferred supplier of CO2 and Dry Ice all across India",
    front_img: SICGILJPG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing"],
    about: `SICGIL have continuously invested for future requirements and have substantial production, storage and distribution assets all across the country and can ensure prompt delivery of any amount or quality of CO2 or Dry Ice that clients may need and provide innovative CO2 solutions.SICGIL particularly preferred by quality conscious clients due to  product and process quality, as well as  ability to deliver on time and trace online.`,
  },
  {
    name: "Solverminds Solutions And Technologies Private Limited",
    description: "Comprehensive Management Solutions for the Maritime Industry",
    front_img: SOLVERMINDSSVG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["technology", "business", "maritime"],
    about: `Incorporated in 2003, Solverminds is a house to domain experts, business consultants, data scientists, OR scientist and technology innovators. Headquartered at Chennai, India,Solverminds is a leading global technology company providing enterprise resource management solutions, consulting and data analytics services to a maritime industry where business meets the technology. The vision of the company is to develop a “Truly” integrated Enterprise Application Suite for the liner shipping company`,
  },

  {
    name: "Techindia Infoway Private Limited",
    description: "Empowering digital health",
    front_img: TECHINDIAPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["medical", "analytics"],
    about: `Established in 2001, Techindia delivers world-class, enterprise healthcare management solutions that bridge the Intersection of Healthcare & Information technology to work more efficiently and collaboratively to deliver better health.Techindia provides exceptional remote Heart rhythm diagnostics and event monitoring solutions with proven track of high performance for the highly regulated industries. Techindia's solution help Physicians, IDTF’s and health systems from the private, public, Government and not-for-profit sectors to, simplify business processes and execute strategies for health management, accountable care, value-based initiatives and develop customer intimacy, while meeting regulatory compliance.`,
  },
  {
    name: "Transtrac Technology Services Pvt Ltd",
    description: "Financial Services & Insurance",
    front_img: TTSPLPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["business"],
    about: `TransTrac Technology Services Private Limited was founded in Jan 2014.Objective of the company is to become a formidable player in the domain of payment solutions mainly focused on providing enterprise-level solutions and services for Payment Acquiring,Transaction Processing,Card Management/Issuance,ATM Managed Services, and Enterprise Cash Management.TransTrac,have designed CredoPay, a futuristic ‘Platform as a Service’ Payment Processing Platform that provides for clientele with an exclusive medium that supports secure and seamless financial processes in any part of the world.`,
  },
  {
    name: "Stenhouse Insurance Brokers Pvt Ltd",
    description:
      "Towards making insurance and risk management more comprehensive and affordable.",
    front_img: STENHOUSEPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["insurance"],
    about: `Stenhouse Insurance Brokers Private Limited, a Company incorporated in India, headquartered at Chennai,is a joint venture Company promoted with equity participation of Newstate Stenhouse Holdings Pte Ltd of Singapore.Stenhouse Insurance Brokers Private Limited was created to provide clients with first-class one stop risk management and insurance broking services in India.Stenhouse is amongst the largest insurance brokerage firms in South India.`,
  },
  {
    name: "Titanium Equipment and Anode MFG. CO (P) LTD",
    description: "45+ Years of Metallurgy Engineering",
    front_img: TEAMPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing"],
    about: `Since our inception in 1977, TEAM also known as Titanium Equipment and Anode Manufacturing Company Ltd, has catalyzed some of the major innovations in the Indian Industrial and Engineering segment – solving some of the most complex engineering challenges for a diverse range of industries in India and around the globe.At TEAM,bring together a powerful team of innovators… driven to provide sophisticated and customer-driven solutions in the manufacturing of exotic metals that include Titanium, Tantalum,etc-made specifically for all industrial applications.`,
  },
  {
    name: "TVS Srichakra Limited",
    description: "The Largest auto ancillary group in India",
    front_img: TVSPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing"],
    about: `TVS Srichakra Limited,Incorporated in 1982 & headquartered in Madurai,makers of TVS Eurogrip brand of tyres is one of India’s leading manufacturers and exporters of Two, Three-Wheeler tyres and Off-Highway tyres.With global research and development capabilities and cutting-edge technology, TVS Srichakra produces industry leading tyres for the automotive sector in India and worldwide.`,
  },
  {
    name: "Virginia Tech India Research & Education Forum",
    description: "Center for Advanced Research and Education",
    front_img: VTIREFPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["research", "education"],
    about: ` Virginia Tech, USA seeks to become one of the world’s top research institutions as well as a center of learning and discovery across the world.An outcome was the founding of the Virginia Tech India Research and Education Forum, a nonprofit entity situated in Chennai. Virginia Tech is committed to being a global Center of learning and an inviting place for faculty and students from around the world to engage in collaborative Learning,Research and Innovation as well as Cultural Exchange.`,
  },
  {
    name: "Vridhi Maritime",
    description: "Towards Quality And Excellence",
    front_img: VRIDHIPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing", "maritime"],
    about: `Established in 2013, Vridhi Maritime Pvt Ltd is a certified professional ship management company.Vridhi Maritime provides a broad range of ship management services that includes technical and crew management and also provide various allied services on various maritime sectors.Vridhi Maritime is growing progressively as the name refers, with total commitment and smart thinking as the strong elements. The professionals associated with VM are of similar minds which is in unison helps us to achieve the goal and not deviate from focus.`,
  },
  {
    name: "GRT",
    description: "Jewellers",
    front_img: GRTPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["manufacturing"],
    about: `GRT Jewellers is one of the India's foremost jewellery store having an exquisite collection of jewellery in Gold, Diamond, Platinum and Silver created by the finest artisans of India.It was in 1964 that G. Rajendran founded GRT Jewellers, in the fast-growing shopping hub of Chennai (formerly Madras).Like all great success stories, GRT as the shop is fondly called by customers, began from humble origins.Through single-minded focus on customer satisfaction, GRT soon became a household name in Chennai, gaining renown for the purity of its ornaments, wide choice of designs and consistent customer delight.`,
  },
  {
    name: "Matrix",
    description: "Rest Assured",
    front_img: MATRIXPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["business"],
    about: `
     Matrix help customers build efficiency in their business.Matrix is a passionate team of professionals providing People, Product, and Process assurance services for enterprises across different industry segments for better compliance and risk mitigation.Listed by NASSCOM (2009) as one of the "Emerge 50" companies, Matrix has its headquarters in Chennai.Matrix was assigned a D&B Rating 4A2 in a recent Dunn and Bradstreet evaluation, classifying the company as ‘good”.`,
  },

  {
    name: "HIMT",
    description: "Education",
    front_img: HIMTPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["educational"],
    about: `Hindustan Institute of Maritime Training (HIMT) is  located in Chennai, Tamil Nadu. HIMT Chennai is affiliated to Indian Maritime University and has been approved by Directorate General of Shipping. It has been rated outstanding (Grade 1) for all its courses. The institute offers wide range courses in the field of nautical science and marine engineering at undergraduate and postgraduate level. HIMT Chennai admissions are done on the basis of IMU-CET conducted by the Indian Maritime University.  `,
  },
  {
    name: "Ramoji",
    description: "Film City",
    front_img: RAMOJIPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["film"],
    about: ` 
    Ramoji Film City is a filmmakers’ paradise and dream destination for holiday makers.Recognised as World’s largest film city by the Guinness World Records.Incepted in 1996, Ramoji Film City was originally conceived as an integrated filmmaking facility offering all services under one roof. The tourism potential was realized and as well as growing desire among public to have a glimpse of the iconic locations. And the gates of Ramoji Film City were thrown open to tourists and it went onto become the premier tourist’s destination in the country.`,
  },
  {
    name: "National Stock Exchange of India Limited",
    description: "International Exchange",
    front_img: NSEPNG,
    background_color: "#FFFFFF",
    color: "#000000",
    category: ["business"],
    about: `National Stock Exchange was incorporated in the year 1992 and commenced operations in 1994 to bring about transparency in the Indian equity markets. NSE was set up at the behest of the Government of India,NSE robust state-of-the-art technology platform offers high levels of robustness, safety and resilience for trading and investment opportunities across all asset classes and for all categories of investors. NSE is focused on investor protection and disciplined development of the Indian capital market landscape.`,
  },
  {
    name: "City Union Bank",
    description: "Banking",
    front_img: CUBPNG,
    background_color: "#FFFFFF",
    color: "#000",
    category: ["banking"],
    about: `City Union Bank Limited is an Indian private sector bank headquartered in Kumbakonam, Tamil Nadu,initially named Kumbakonam Bank Limited, and was incorporated on 1904.The bank in the beginning preferred the role of a regional bank and slowly but steadily built for itself a place across Tamil Nadu and outside the state of Tamil Nadu.Taking into account the bank's financial strength, managerial competence and consistent progress in all spheres of its activities,RBI has granted an Authorised Dealers License to deal in Foreign Exchange business with effect from October, 1990.`,
  },
];
