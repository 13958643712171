import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./DualColumn.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import { Bounce, Fade, Reveal } from "react-awesome-reveal";

function DualColumn(props) {
  return (
    <Container>
      <Row>
        <Col lg={true}>
            <Image src={props.aboutSrc} alt="Image" />
        </Col>
        <Col lg={true} className="aboutus">
          <Reveal  triggerOnce duration={800} delay={0}>
            <h2>{props.title}</h2>
          </Reveal>

          <Reveal triggerOnce duration={800} delay={300}>
            <p>{props.desc}</p>
          </Reveal>

          <Reveal  triggerOnce duration={800} delay={500}>
            <ProgressBar />
          </Reveal>

        </Col>
      </Row>
    </Container>
  );
}

export default DualColumn;
