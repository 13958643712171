import React, { useEffect, useState } from "react";
import "./OurClientCard.css";

import { Button } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import CustomModal from "../CustomModal/CustomModal";
import { clients_list } from "./clients";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import clientImg from "../../assets/images/client-images/clients_increase.png";

function OurClientCard() {
  return (
    <div
      className="d-flex flex-column align-items-center container-align"
      style={{ marginBottom: "10em" }}
    >
      <div className="container-client">
        {clients_list.map((client) => (
          <div className="grid-item">
            <img
              src={client.front_img}
              alt={client.name}
              className="client-image"
            />
          </div>
        ))}

        <div className="flex">
          <div>
            <img src={clientImg} alt="client-logo" />
          </div>
          <div className="client-text">
            <h1>And More...</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurClientCard;
