import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Features.css";
import { Reveal } from "react-awesome-reveal";

function Features() {
  const features = [
    {
      id: 1,
      title: "Assurance",
      description: "Assured alleviation of clients’ issue.",
      icon: "bx-check-shield",
    },
    {
      id: 2,
      title: "Integrity",
      description: "Genuine security process.",
      icon: "bx-lock",
    },
    {
      id: 3,
      title: "Complete Cyber Security",
      description: "Ensured safety of your web / network.",
      icon: "bx-certification",
    },
  ];
  const featuresList = features.map((feature, idx) => {
    return (
      <Col lg={true} style={{ padding: "0" }} key={`features_${idx}`}>
        <Reveal duration={800} delay={500*idx} triggerOnce>
        <div className="single-features">
          <h3>
            <i className={`bx ${feature.icon}`}></i>
            {feature.title}
          </h3>
          <p>{feature.description}</p>
        </div>
        </Reveal>
      </Col>
    );
  });

  return (
    <Container id="feature-section">
      <Row className="row-features">{featuresList}</Row>
    </Container>
  );
}

export default Features;
