import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Navigationbar from "../../components/NavBar/Navigationbar";
import TopBar from "../../components/Top-Bar/TopBar";
import PageTitle from "../../components/PageTitle/PageTitle";

function HomeLayout() {
  const location = useLocation();

  
  return (
    <>
      <TopBar location={location} />
      <Navigationbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default HomeLayout;
