import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import ImageColumn from "../../components/Image-Column/Image-Column";
import PageTitle from "../../components/PageTitle/PageTitle";
import AboutPagePNG from "../../assets/images/about-page.png";
import Logo1PNG from "../../assets/images/logo1.png";

import "./AboutUs.css";

function AboutUs() {

  const dealProp = {
    title: "The Necurity Deal",
    desc: "(What to expect from Necurity?)",
    src: AboutPagePNG,
  };
  return (
    <>
      <div className="container about-us">
        <div className="about-us-h2">
          <h2>About Us</h2>
          <p>
            Necurity Solutions is a holistic information technology security
            solution provider. We focus on providing innovative security
            solutions, to ensure the value of your digital assets. We do this
            through our application security (web & mobile), network security
            (enterprise & IT), data protection (security & theft), protection
            against malware / ransomware / bots and cloud security.
          </p>
        </div>
      </div>
      <ImageColumn
        title={dealProp.title}
        src={dealProp.src}
        desc={dealProp.desc}
      />
      <Container style={{ backgroundColor: "#010c16", padding: "5%" }}>
        <Row>
          <Col lg={true}>
            <ul className="tabs active">
              <li className="current">
                <a href="#">Our Mission </a>
              </li>
            </ul>
            <div className="tab_content">
              <div className="tabs_item">
                <p className="para-align">
                  To help you avail the absolute advantage of information
                  technology in business sustenance and expansion, without the
                  anxiety about the safety of your digital assets, through our
                  affordable and innovate security solutions.
                </p>
              </div>
            </div>
          </Col>

          <div className="col-lg-6">
            <div className="electronic-img">
              <img src={Logo1PNG} alt="Image" />
            </div>
          </div>
        </Row>
      </Container>
      <div className="container">
        <div className="section-title">
          <h2>Necurity Norm (In this Digital World)</h2>
          <p className="para">
            We protect your digital assets, perfect your online presence, and
            prevent cyber threats in your path to success.
          </p>
          <p className="para">
            (Our Vector Approach to Cyber Security) The Necurity course to Cyber
            Security
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6 norm">
            <div className="single-security">
              <i className="fa-solid fa-magnifying-glass-chart"></i>
              <h3>Requirement analysis:</h3>

              <p>
                An exhaustive analysis of client’s issue is done, the solution
                identified and the process is recommended.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 norm">
            <div className="single-security">
              <i className="fa-solid fa-square-pen"></i>

              <h3>Proposal finalisation</h3>

              <p>
                Based on the recommendation, a proposal on the best security
                technique is built.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 norm">
            <div className="single-security">
              <i className="fa-solid fa-user-astronaut"></i>

              <h3>Portal Credentials generation</h3>

              <p>
                On finalisation of the proposal, portal credentials are
                generated.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 norm">
            <div className="single-security">
              <i className="fa-solid fa-gears"></i>

              <h3>Necurity solution implementation</h3>

              <p> The Necurity solution for cyber security is implemented.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-title">
        <h1>STEPS</h1>
      </div>
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col">
            <h4>1. Security Gap Analysis</h4>
          </div>
          <div className="col">
            <h4>2. Proposal finalization</h4>
          </div>
          <div className="col">
            <h4>3. Cyber Security Protection Starts</h4>
          </div>
          <div className="col">
            <h4>4. 360 Degree Cyber Security Report Delivered</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
