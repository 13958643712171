import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CarouselServices.css";
import OurServicesJPG from "../../assets/images/OurServicesBg.jpg";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Reveal, Slide } from "react-awesome-reveal";

function CarouselServices() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <Card
      className="carousel-service"
        style={{
          backgroundImage: `url(${OurServicesJPG})`,
        }}
      >
        {/* <Card.Img src={OurServicesJPG} alt="OurServicesBg" /> */}

      
        <Card.Body style={{ textAlign: "center" }}>
        <Card.Title
          className="carousel-services"
          style={{ textAlign: "center" }}
        >
          Our Services
        </Card.Title>
          <Container>
            <Carousel
              additionalTransfrom={0}
              arrows
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={true}
              responsive={responsive}
            >
               <Reveal triggerOnce duration={1000}>
              <div className="single-list-item">
                <span>
                  <i className="fa-solid fa-network-wired carousel-icons"></i>
                </span>
                <h3>
                  Network Penetration Testing<br></br>
                </h3>
                <p>
                  We protect the security of your network with our network
                  penetration testing. We generate a complete cyber audit report
                  on the network.
                </p>

                <a
                  href="/services/network-penetration-testing"
                  className="read-more"
                >
                  Read More
                </a>
              </div>
              </Reveal>
              <Reveal triggerOnce delay={600} duration={1000}>
              <div className="single-list-item">
                <span>
                  <i className="fa-solid fa-bug"></i>
                </span>
                <h3>
                  Web Application Security Assessment<br></br>
                </h3>

                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>

                <a
                  href="/services/web-application-security-assessment"
                  className="read-more"
                >
                  Read More
                </a>
              </div></Reveal>

              <Reveal  triggerOnce  delay={1200} duration={1000}>
              <div className="single-list-item">
                <span>
                  <i className="fa-solid fa-mobile-screen-button"></i>
                </span>
                <h3>
                  Mobile Application Penetration Testing<br></br>
                </h3>

                <p>
                  We perform a comprehensive audit on Android and IOS mobile
                  application to fix OWASP vulnerabilities.
                </p>

                <a
                  href="/services/mobile-application-penetration-testing"
                  className="read-more"
                >
                  Read More
                </a>
              </div>
              </Reveal>
              <div className="single-list-item">
                <span>
                  <i className="fa-solid fa-brands fa-redhat"></i>
                </span>
                <h3>
                  Red Teaming<br></br>
                </h3>

                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>

                <a href="/services/red-teaming" className="read-more">
                  Read More
                </a>
              </div>
              <div className="single-list-item">
                <span>
                  <i className="fa-solid fa-eye"></i>
                </span>
                <h3>
                  Virtual Chief Cyber Security Officer<br></br>
                </h3>

                <p>
                  Potential perpetration of cyber threats to your website is
                  assessed through our website penetration testing.
                </p>

                <a
                  href="/services/virtual-chief-cyber-security-officer"
                  className="read-more"
                >
                  Read More
                </a>
              </div>
              <div className="single-list-item">
                <span>
                  <i className="fa-solid fa-shield-halved"></i>
                </span>
                <h3>
                  Security Operations Centre<br></br>
                </h3>

                <p>
                  This includes the collection and analysis of data to identify
                  suspicious activity and improve the organization&#8217;s
                  security.
                </p>

                <a
                  href="/services/security-operations-centre"
                  className="read-more"
                >
                  Read More
                </a>
              </div>
            </Carousel>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
}

export default CarouselServices;
