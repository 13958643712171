import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "./Careers.css";

const life_in_necurity_list = [
  {
    description: "Flexible Working Hours",
    icon: "business-time",
  },
  {
    description: "Learning & Development",
    icon: "book-reader",
  },
  {
    description: "Special Mentoring",
    icon: "fist-raised",
  },
];

const job_opportunities_list = [
  { role: "Cyber Security Officer", vacancy: 0 },
  { role: "Cyber Security Officer", vacancy: 0 },
  { role: "Cyber Security Officer", vacancy: 0 },
];

function LifeInCard({ card_info }) {
  return (
    <Col sm={12} md={6} lg={4} className="p-4">
      <div className="d-flex justify-content-center">
        <i className={`fa-solid fa-${card_info.icon} carousel-icons`}></i>
      </div>
      <h3 className="d-flex justify-content-center font-barlow text-center">
        {card_info.description}
      </h3>
    </Col>
  );
}

function JobOpporunitiesCard({ card_info }) {
  return (
    <Col sm={12} md={6} lg={4} className="p-3">
      <Card className="job-card px-3 py-4">
        <Card.Body>
          <h2>{card_info.role}</h2>
          <p className="text-dark">No: of Openings: {card_info.vacancy}</p>
        </Card.Body>
        <Card.Footer className="job-card-footer">
          <Button className="btn-custom">Apply Now</Button>
        </Card.Footer>
      </Card>
    </Col>
  );
}

function Careers() {
  const pageTitle = {
    title: "Careers",
    previous: "Home",
  };

  return (
    <>
      <div className="career-page">
        <div className="inspiraion-section">
          <Container>
            <Col>
              <Row>
                <h1 className="fw-bold font-barlow text-center ">
                  Inspirations Meets Aptitude
                </h1>
              </Row>
              <Row>
                <p className="text-dark description-text">
                  We offer a creative learning environment and self-development
                  opportunities that help you set the bar higher in your every
                  endeavour.If you are piqued by the challenges in providing a
                  safe net experience, you are the one we are looking for.
                </p>
              </Row>
            </Col>
          </Container>
        </div>
        <div className="why-section">
          <Container>
            <Row>
              <Col xs={12} lg={3}>
                <h1 className="why-title fw-bold font-barlow ">
                  Why
                  <br /> Necurity
                </h1>
              </Col>
              <Col xs={12} lg={8}>
                <ul className="mt-4">
                  <li>
                    <i className="fab fa-slack" aria-hidden="true"></i>&nbsp; We
                    Provide Vulnerability Assessments, Network Audits And
                    Penetration Testing Both Manually And Remotely.
                  </li>
                  <br />
                  <li>
                    <i className="fab fa-slack" aria-hidden="true"></i>&nbsp;
                    Our Unique Customization Of Revolutionary Networks For
                    Real-Time Internal Or Organic Sources Reflects Our
                    Expertise.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="lifein-section mb-5">
          <Container>
            <Col>
              <Row>
                <h1 className="fw-bold font-barlow text-center my-5">
                  Life In Necurity
                </h1>
              </Row>
              <Row className="justify-content-center justify-items-center">
                {life_in_necurity_list.map((single_card, idx) => {
                  return (
                    <LifeInCard
                      key={`life_in_${idx}`}
                      card_info={single_card}
                    />
                  );
                })}
              </Row>
            </Col>
          </Container>

          <Container>
            <Row>
              <Col className="d-flex justify-content-center justify-items-center">
                <ul className="life-in-perks-card">
                  <li>
                    <i className="fas fa-check" aria-hidden="true"></i>
                    &nbsp;Step by step guidance
                  </li>
                  <li>
                    <i className="fas fa-check" aria-hidden="true"></i>
                    &nbsp;Pre designed layout options
                  </li>
                  <li>
                    <i className="fas fa-check" aria-hidden="true"></i>
                    &nbsp;Learning & Development
                  </li>
                </ul>
              </Col>
              <Col className="d-flex justify-content-center justify-items-center">
                <ul className="life-in-perks-card">
                  <li>
                    <i className="fas fa-check" aria-hidden="true"></i>
                    &nbsp;Competitive compensation
                  </li>
                  <li>
                    <i className="fas fa-check" aria-hidden="true"></i>
                    &nbsp;Healthy work-life balance
                  </li>
                  <li>
                    <i className="fas fa-check" aria-hidden="true"></i>
                    &nbsp;Simple drag and drop editor
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          <Container>
            <Col>
              <Row>
                <h1 className="fw-bold font-barlow text-center my-5">
                  JOB OPPORTUNITIES
                </h1>
              </Row>
              <Row className="justify-content-center justify-items-center">
                {job_opportunities_list.map((single_card, idx) => {
                  return (
                    <JobOpporunitiesCard
                      key={`job_opportunties_${idx}`}
                      card_info={single_card}
                    />
                  );
                })}
              </Row>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Careers;
