import React from "react";
import { Col, Row } from "react-bootstrap";
import "./OurServiceItem.css";

function OurServiceItem(props) {
  return (
    <Row style={{ padding: "1%" }}>
      <Col
        sm={6}
        md={6}
        lg={6}
        style={{ padding: "1%" }}
        className="services-col"
      >
        <div
          className="single-solutions"
          style={{ backgroundImage: `url(${props.imgSrc1})` }}
        >
          <div className="solutions-content">
            <h3>
              {props.title1}
              <br />
              <br />
            </h3>
            <p>{props.desc1}</p>
            <a className="read-more-our-services" href={props.link1}>
              Read more
            </a>
          </div>
        </div>
      </Col>
      <Col sm={6} md={6} lg={6} style={{ padding: "1%" }}>
        <div
          className="single-solutions"
          style={{ backgroundImage: `url(${props.imgSrc2})` }}
        >
          <div className="solutions-content">
            <h3>
              {props.title2}
              <br />
              <br />
            </h3>
            <p>{props.desc2}</p>
            <a className="read-more-our-services" href={props.link2}>
              Read more
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default OurServiceItem;
