import { React } from "react";
import { NavLink, Link } from "react-router-dom";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import Logo1PNG from "../../assets/images/logo1.png";
import "./Navbarstyles.css";

function Navigationbar() {
  return (
    <>
      <Navbar expand="lg" className="navContainer" sticky="top">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img className="logo" src={Logo1PNG} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="nav-burger-menu shadow-none"
          >
            <i
              className="fa-solid fa-bars nav-burger-menu"
              style={{ paddingBottom: "0" }}
            ></i>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "500px" }}
              navbarScroll
            >
              <NavLink className="nav-items" as={Link} to={"/"}>
                Home
              </NavLink>
              <NavLink className="nav-items" as={Link} to={"/about-us"}>
                About Us
              </NavLink>
              <NavLink className="nav-items" as={Link} to={"/services"}>
                Services
              </NavLink>
              <NavLink className="nav-items" as={Link} to={"/products"}>
                Products
              </NavLink>
              <NavLink
                className="nav-items"
                as={Link}
                to="https://blog.necurity.com"
                target="_blank"
              >
                Blog
              </NavLink>
              {/* <NavLink className="nav-items" as={Link} to={"/events"}>
                Events
              </NavLink> */}
              <NavLink className="nav-items" as={Link} to={"/careers"}>
                Careers
              </NavLink>
              <NavLink className="nav-items" as={Link} to={"/our-clients"}>
                Our Clientele
              </NavLink>
              <NavLink className="nav-items" as={Link} to={"/contact-us"}>
                Contact Us
              </NavLink>
              <NavLink className="nav-items" as={Link} to={"/instructions"}>
                Instructions
              </NavLink>
            </Nav>
            <div className="others-option">
              <div className="call-us">
                <Button
                  variant="custom-phone"
                  className="d-flex justify-content-center align-items-center"
                >
                  <i className="bx bxs-phone-call"></i>
                  &nbsp; +91-8939 056 056
                </Button>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigationbar;
